import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import StoriesResultsContainer from 'containers/stories/stories-results';

// eslint-disable-next-line
const AuthorTemplate = ({ data, pageContext, location }) => {
  let stories = data.allStoriesJson.edges;

  if (!data?.author) {
    return null;
  }

  const author = data.author.name;

  if (data.avatar.edges.length > 0) {
    stories = stories.map((story) => {
      story.node.author.image = data.avatar.edges[0].node.image;
      return story;
    });
  }

  return (
    <Layout location={location}>
      <SEO title={`Author: ${author}`} />
      <Header fixedDisplayVarient={true} />
      <main className="site-wrapper-reveal">
        <StoriesResultsContainer
          className="left-red-slice md-no-bg-image"
          title={author}
          stories={stories.map((story) => story.node)}
        />
      </main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query BlogByAuthorQuery($author: String!) {
    allStoriesJson(
      sort: { fields: postedDate, order: DESC }
      filter: { author: { name: { eq: $author } } }
    ) {
      edges {
        node {
          id
          title
          slug
          excerpt
          body
          postedDate(formatString: "LL")
          categories {
            title
          }
          author {
            name
          }
          photo {
            childImageSharp {
              fluid(maxWidth: 1170, maxHeight: 570, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
                aspectRatio
              }
            }
          }
        }
      }
    }
    author: contributorsJson(name: { eq: $author }) {
      name
      role
      bio
    }
    avatar: allAuthorsJson(filter: { name: { eq: $author } }) {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`;

export default AuthorTemplate;
