import styled from 'styled-components';
import { device } from 'theme';

export const StoriesWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${device.xsmall} {
    grid-template-columns: 1fr;
  }
`;

export const CategoryTemplateWrapper = styled.div`
  padding-bottom: 100px;
`;
