import React, { FC } from 'react';
import { Container } from 'components/ui/wrapper';
import {
  CategoryTemplateWrapper,
  StoriesWrapper,
} from './stories-results.style';
import Filters from 'containers/stories/filters';
import StoryPreview from 'components/blog/story-preview';
import BackButton from 'components/ui/back-button';
import Heading from 'components/ui/heading';
import { defaultDropdownStyling } from 'components/ui/filter-dropdown';

interface Props {
  title: string;
  stories: Story[];
  className?: string;
  headingStyle?: object;
  filtersStyle?: object;
}

interface Story {
  id: string;
  title: string;
  slug: string;
  postedDate: string;
  excerpt: string;
  body: string;
  photo: object;
}

const StoriesResultsContainer: FC<Props> = ({
  title,
  stories,
  className,
  headingStyle,
  filtersStyle,
}) => (
  <CategoryTemplateWrapper className={className}>
    <Container className="pb-5">
      <Filters filtersStyle={filtersStyle} />
      <Heading {...headingStyle}>{title}</Heading>
      <StoriesWrapper>
        {stories.map((story, idx) => (
          <StoryPreview key={`${story.id}-${idx}`} {...story} />
        ))}
      </StoriesWrapper>
      <BackButton />
    </Container>
  </CategoryTemplateWrapper>
);

StoriesResultsContainer.defaultProps = {
  headingStyle: {
    as: 'h3',
    mb: '70px',
    textalign: 'center',
  },
  filtersStyle: {
    ...defaultDropdownStyling,
    container: (container: any, state: any) => ({
      ...container,
      borderRadius: '10px',
      border: '1px solid #6D7072',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? '0px' : '10px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? '0px' : '10px',
      fontWeight: 600,
      paddingRight: '10px',
      backgroundColor: '#fff',
      boxShadow: 'none',
    }),
    menu: (menu: any) => ({
      ...menu,
      left: '-1px',
      width: 'calc(100% + 2px)',
      boxShadow: 'none',
      marginTop: '0px',
      border: '1px solid #6D7072',
      borderRadius: '0px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
  },
};

export default StoriesResultsContainer;
