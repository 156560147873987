import styled from 'styled-components';
import { StyledComponentsBaseStylingProps } from 'theme';

export const Subtitle = styled.h6< StyledComponentsBaseStylingProps>`
  color: ${(props) => props.theme.colors.subHeadingColor};
  font-weight: 600;
  margin: ${(props) => props.m || '-5px 0px 21px'};
  text-transform: uppercase;
  font-size: ${(props) => props.fontSize || '20px'};
  letter-spacing: 2px;
  line-height: 1.43;
`;
