import React, { FC } from 'react';
import { ButtonStyles } from '../button';
import { navigate } from '@reach/router';
import { StyledBackArrow, StyledButton } from './back-button.style';
import leftArrow from 'assets/images/icons/arrow-left.png';

interface BackButtonProps {
  title?: string;
  buttonStyle?: ButtonStyles;
}

const BackButton: FC<BackButtonProps> = ({ title = 'Back', buttonStyle }) => (
  <StyledButton
    varient="texted"
    onClick={() => navigate(-1)}
    icon={<StyledBackArrow src={leftArrow} alt="back arrow" />}
    {...buttonStyle}
  >
    {title}
  </StyledButton>
);

BackButton.defaultProps = {
  buttonStyle: {
    fontSize: '26px',
    iconposition: 'left',
  },
};

export default BackButton;
