import styled from 'styled-components';
import Button from '../button';

export const StyledButton = styled(Button)`
  &:before {
    content: '';
    padding: 0;
  }
`;

export const StyledBackArrow = styled.img`
  height: 35px;
  padding-right: 9px;
  margin-top: -5px;
`;
