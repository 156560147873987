import Heading from 'components/ui/heading';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { ImageWrapper, StoryPreviewWrapper } from './story-preview.style';
import Image from 'gatsby-image';
import Button from 'components/ui/button';
import Subtitle from 'components/ui/subtitle';
import { StyledComponentsBaseStylingProps } from 'theme';

interface Props {
  photo: any;
  title?: string;
  postedDate?: string;
  excerpt?: string;
  slug?: string;
  titleStyle?: StyledComponentsBaseStylingProps;
}

const StoryPreview: FC<Props> = ({
  photo,
  title,
  postedDate,
  excerpt,
  slug,
  titleStyle,
}) => (
  <StoryPreviewWrapper>
    {photo && (
      <ImageWrapper>
        <Image fluid={photo.childImageSharp.fluid} />
      </ImageWrapper>
    )}
    {title && <Heading {...titleStyle}>{title}</Heading>}
    {postedDate && <Subtitle>{postedDate}</Subtitle>}
    {excerpt && <ReactMarkdown>{excerpt}</ReactMarkdown>}
    {slug && (
      <Button varient="texted" to={`/news/${slug}`}>
        Read more
      </Button>
    )}
  </StoryPreviewWrapper>
);

StoryPreview.defaultProps = {
  titleStyle: {
    as: 'h3',
    fontSize: '24px',
    mb: '16px',
  },
};

export default StoryPreview;
